$cardsBackground: #2c2f32;
$cardsRed: #d8574e;
$cardsGreen: #5cb789;
$cardsGrey: #5b5b5b;
$infoBackground: #2c3032;
$colorGrey: #848587;

.font-12 {
  color: $colorGrey !important;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  line-height: 1 !important;
  font-weight: 500 !important;
  display: flex;

  span {
    color: $colorGrey !important;
    font-family: "Montserrat" !important;
    font-size: 12px !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    margin-right: 5px;
  }
}
.fc {
  justify-content: center;
}
.tac {
  text-align: center;
}
.font-14 {
  font-size: 14px !important;
}
.mt25 {
  margin-top: 25px;
}
.font-44 {
  color: #fff !important;
  font-family: "Oranienbaum" !important;
  font-size: 44px !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  letter-spacing: -1.76px !important;
  text-transform: uppercase !important;

  span {
    color: #fff !important;

    font-family: "Oranienbaum" !important;
    font-size: 44px !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    letter-spacing: -1.76px !important;
    text-transform: uppercase !important;
  }
}
.font-34 {
  color: #fff !important;
  font-family: "Oranienbaum" !important;
  font-size: 34px !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  letter-spacing: -1.76px !important;
  text-transform: uppercase !important;
}
.font-22 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.88px;
  text-transform: uppercase;
}
.range-slider .range-slider__thumb[data-disabled] {
  display: none !important;
}
.range-slider {
  background: rgba(217, 217, 217, 0.1) !important;
  border-radius: 0 !important;
}
.range-slider__range {
  background-color: #ffffff !important;
  left: 0 !important;
  transition: unset !important;
}
.range-slider__thumb {
  width: 29px !important;
  height: 38px !important;
  background: transparent !important;
  border-radius: 0 !important;
  transition: unset !important;
  top: -2px !important;
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;

    height: 100%;
    background: url("../../../assets/images/cards/girl.png") 0 0 / contain no-repeat !important;
    z-index: 2;

    transform: scale(1.1);
  }
  &:before {
    content: attr(aria-valuenow) "%";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -26px;
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    z-index: 3;
    pointer-events: none;
    color: #959697;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
}
