.deposit {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9000;
    background: rgba(18, 18, 18, 0.5);
    backdrop-filter: blur(12px);
    transition: all 0.3s linear;
    pointer-events: none;
    opacity: 0;
    &.active {
        pointer-events: visible;
        opacity: 1;
    }
}
.depositBody {
    height: 100%;
    overflow: auto;
}
.depositContentImgsBg {
    font-size: 0;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        bottom: 0;
        position: absolute;
        width: 50%;
        height: 50%;
        background: url("../../../assets/images/wallet/deposit/bg.png") 0 bottom no-repeat;
        display: none;
        @media (max-width: 600px) {
            display: block;
        }
        @media (max-height: 790px) {
            display: block;
        }
    }
    &:before {
        content: "";
        display: inline-block;
        right: 1px;
        bottom: 0;
        position: absolute;
        width: 50%;
        height: 50%;
        background: url("../../../assets/images/wallet/deposit/bg.png") right bottom no-repeat;
        display: none;
        @media (max-width: 600px) {
            display: block;
        }
        @media (max-height: 790px) {
            display: block;
        }
    }
}
.depositContent {
    max-width: 558px;
    margin: 50px auto 40px auto;
    position: relative;

    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 50%;
        height: 100%;
        background: url("../../../assets/images/wallet/deposit/bg.png") 0 0 no-repeat;
        @media (max-width: 600px) {
            height: 50%;
        }
        @media (max-height: 790px) {
            height: 50%;
        }
    }
    &:before {
        content: "";
        display: inline-block;
        right: 1px;
        top: 0;
        position: absolute;
        width: 50%;
        height: 100%;
        background: url("../../../assets/images/wallet/deposit/bg.png") right 0 no-repeat;
        @media (max-width: 600px) {
            height: 50%;
        }
        @media (max-height: 790px) {
            height: 50%;
        }
    }
    @media (max-width: 600px) {
        margin: 20px auto 40px auto;
    }
    @media (max-height: 790px) {
        margin: 20px auto 40px auto;
    }
}
.depositContentWrap {
    position: relative;
    z-index: 3;
    padding: 60px 50px 50px 50px;
    height: 729px;
    @media (max-width: 600px) {
        padding: 40px 25px 50px 25px;
        height: 600px;
    }
    @media (max-height: 790px) {
        padding: 40px 25px 50px 25px;
        height: 600px;
    }
}
.depositContentClose {
    position: absolute;
    right: -4px;
    top: -7px;
    cursor: pointer;
}
.depositContentTitle {
    color: #080808;
    text-align: center;
    font-family: "Oranienbaum";
    font-size: 49px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1.96px;
    text-transform: uppercase;
}
.depositContentSubtitle {
    color: #09090b;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 50px;
}
.depositContentOverText {
    color: #828283;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 5px;
}
.depositContentList {
}
.depositContentListHead {
    background-color: #e7e7e7;
    border-radius: 100px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 9px;
    margin-bottom: 18px;
    @media (max-width: 600px) {
        height: 45px;
    }
    @media (max-height: 790px) {
        height: 45px;
    }
}
.depositContentListHeadLeft {
    color: #060608;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    span {
        color: #767677;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.56px;
        margin-left: 4px;
        text-transform: uppercase;
    }
    img {
        width: 53px;
        margin-right: 5px;
        @media (max-width: 600px) {
            width: 30px;
        }
        @media (max-height: 790px) {
            width: 30px;
        }
    }
}
.depositContentListHeadRight {
}
.depositContentInput {
    background-color: #e7e7e7;
    border-radius: 100px;
    input {
        background: transparent;
        padding: 0 22px;
        height: 100%;
        width: 100%;
        border: unset;
        color: #060608;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        height: 67px;
        line-height: 67;
        line-height: 80%;
        letter-spacing: -0.56px;
        text-transform: uppercase;
        @media (max-width: 600px) {
            height: 45px;
            line-height: 45px;
        }
        @media (max-height: 790px) {
            height: 45px;
            line-height: 45px;
        }
        &:focus,
        &:active {
            border: unset !important;
            outline: unset !important;
        }
    }
}
.depositContentRow {
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
}
.depositContentRowLeft {
    color: white;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    @media (max-width: 600px) {
        font-size: 12px;
    }
    @media (max-height: 790px) {
        font-size: 12px;
    }
    &.error {
        color: red;
    }
    &.summary {
        font-size: 18px;
        font-weight: 600;
    }
}
.depositContentRowRight {
    color: #080808;
    text-align: right;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6;
    @media (max-width: 600px) {
        font-size: 12px;
    }
    @media (max-height: 790px) {
        font-size: 12px;
    }
}
.depositContentBtn {
    border-radius: 61px;
    border: 1px solid #09090b;
    line-height: 67px;
    height: 67px;
    text-align: center;
    margin-top: 44px;
    color: #09090b;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    @media (max-width: 600px) {
        height: 45px;
        line-height: 45px;
    }
    @media (max-height: 790px) {
        height: 45px;
        line-height: 45px;
    }
}
