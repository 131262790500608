@import "./colors";

.view {
  display: block;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-bottom: 130px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}

.viewTop {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 0 49px;
  position: relative;
  z-index: 3;
  height: 108px;
  @media (max-width: 1200px) {
    padding: 0 25px;
  }
  @media (max-width: 850px) {
    display: none;
  }
}

.view {
  :global(.color1) {
    .viewTopColumn {
      &:nth-child(1) {
        color: #fff;

        span {
          border-color: #FFFFFF;
          color: #fff;
        }
      }
    }
  }

  :global(.color2) {
    .viewTopColumn {
      &:nth-child(1) {
        color: #FFFFFF;

        span {
          color: #FFFFFF;
        }
      }

      &:nth-child(3) {
        color: #fff !important;

        span {
          color: #fff !important;
        }
      }
    }
  }

  :global(.color3) {
    .viewTopColumn {
      &:nth-child(1) {
        color: #FFFFFF;

        span {
          color: #FFFFFF;
        }
      }

      &:nth-child(3) {
        color: #FFFFFF;

        span {
          color: #FFFFFF;
        }
      }

      &:nth-child(5) {
        color: #fff;

        span {
          color: #fff;
        }
      }
    }
  }
}

.viewTopColumn {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 0;
  }
  color: #5e6265;

  span {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5e6265;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 10px;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:last-child {
        filter: blur(10px);
      }
    }
    @media (max-width: 1000px) {
      font-size: 14px;
    }
    @media (max-width: 850px) {
      margin-right: 0;
    }
  }
}

.viewTopLine {
  height: 1px;
  flex: 1 1 auto;
  font-size: 0;
  border: 1px dashed #5d6062;
}

.viewTab {
  display: none;

  &.active {
    padding-top: 83px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: center;
      text-align: center;
      padding-top: 448px;
    }
  }

  position: relative;
  padding-bottom: 150px;


  &.two {

    @media (max-width: 768px) {
      padding-top: 120px;
    }
  }

  &.three {

    @media (max-width: 768px) {
      padding-top: 120px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}

.viewTabContent {
  flex: 0 1 450px;
}

.viewTabRestoreContent {
  flex: 0 1 550px;
}

.viewTabContentTitle {
  color: #FFF;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
  text-align: left;
  font-size: 45px;
  &.first {
    font-size: 56.51px;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    &.otherPage {
      font-size: 42.51px;
    }
  }
}

.viewTabContentSubTitle {
  border-radius: 11px;
  background: #005c90;
  display: inline-block;
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  padding: 8px 15px;
  font-weight: 600;
  transform: rotate(5deg);
  position: relative;
  top: -25px;
  @media (max-width: 480px) {
    top: -15px;
  }
}

.viewTabContentText {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 17.388px;
  line-height: 1.6;
  max-width: 343px;
  margin-top: 10px;
  text-align: left;
  &.first {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    &.first {
      margin: 17px auto 0;
    }
  }
}

.viewTabContentBtn {
  margin-top: 70px;
  color: #080809;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 17px 70px;
  border-radius: 61px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  @media (max-width: 1000px) {
    margin-top: 35px;
  }
}

.viewTabContentRow {
  margin-top: 44px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  @media (max-width: 480px) {
    max-width: 350px;
    margin: 34px auto 0;
  }
}

.viewTabContentRowWord {
  border-radius: 8px;
  background: #272727;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 6px 24px 6px 7px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  gap: 12px;

  span {
    text-transform: uppercase;
    width: 36px;
    min-width: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.3);
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 90%; /* 10.8px */
    letter-spacing: -0.48px;
  }
}

.viewTabContentRowWord.restore {
  max-width: calc(100% / 3 - 2 / 3 * 8px);
  @media (max-width: 768px) {
    max-width: calc(100% / 2 - 1 / 2 * 8px);
  }
}

.viewTabContentBtns {
  display: flex;
  align-items: center;
}

.viewTabContentBtnsNext {
  border-radius: 61px;
  background: #fff;
  color: #080809;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  height: 67px;
  line-height: 67px;
  flex: 0 1 278px;
  margin-right: 10px;
  @media (max-width: 480px) {
    flex: 0 1 50%;
  }
}

.viewTabContentBtnsCopy {
  border-radius: 61px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 67px;
  line-height: 67px;
  flex: 0 1 128px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 480px) {
    flex: 0 1 50%;
  }

  img {
    margin-right: 10px;
  }
}
